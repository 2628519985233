import { UnknownPackage } from '../store/configurator';

/**
 * Accept comma separated list of parts and a new part that needs to be added to the list.
 * The function will then add it and ensure uniqueness of the parts.
 * @returns string comma separated list of parts
 */
export function addUniquePartToPrString(parts: string, newPart: string) {
  return [
    ...new Set(
      [
        // convert to array
        parts.split(','),
        // add new part, but mind that it may be a group
        ...newPart.split(','),
        // flatten to remove nesting, if applicable
      ].flat(),
    ),
  ].join(',');
}

/**
 * Walk through options (usually colors) and ensure that each option is unique by a given property (usually ID)
 * @param options
 * @param uniqueProperty
 */
export function uniqueOptionsByProperty<T = UnknownPackage>(options: T[], uniqueProperty = 'ae_color'): T[] {
  const uniqueOptions = new Map();
  options.forEach(option => {
    const uniqueKey = `${option[uniqueProperty]}`;
    if (!uniqueOptions.has(uniqueKey)) {
      uniqueOptions.set(uniqueKey, option);
    } else {
      const existing = uniqueOptions.get(uniqueKey);
      uniqueOptions.set(uniqueKey, {
        ...existing,
        id: addUniquePartToPrString(existing.id, option['id']),
      });
    }
  });

  return [...uniqueOptions.values()];
}
