import { Env } from '@stencil/core';
import store, { UnknownPackage, PartId, state } from '../store/configurator';
import { PackageModel, PACKAGES_ENDPOINT } from './PackageModel';
import { fetchData } from './fetch';
import { uniqueOptionsByProperty } from './unique';

type ModelConfig = Partial<Pick<PackageModel, 'prString' | 'packageIdConst' | 'package' | 'carModel'>>;

type Mutations = {
  filter?: Record<string, unknown[]>;
};

export type GroupedItem = {
  label: string;
  order: number;
};

export type PartGrouping = Record<PartId, GroupedItem>;

/**
 * A collection of package models. Useful when we have multiple prStrings for a single package.
 */
export class PackageCollection<PackageType = UnknownPackage> {
  modelConfig: ModelConfig = {};

  private allModels: Record<string, PackageModel<PackageType>> = {};

  models: Record<string, PackageModel<PackageType>> = {};

  meta: Record<string, Record<string, unknown>> = {};

  mutations: Mutations = {};

  // All options from all models as retrieved from the API
  options: (PackageType & { pr_rule?: string })[] = [];

  valueField = 'ae_color';

  partIdField = 'id';

  groupingField = 'id';

  /**
   * Grouped models
   */
  groups: Map<GroupedItem, PackageModel<PackageType>[]> = new Map();

  constructor(config: ModelConfig = {}, mutations: Mutations = {}) {
    this.modelConfig = config;

    this.mutations = mutations;
  }

  async initModels(property = 'values', groupingKey = this.groupingField) {
    if (!this.options?.length) {
      const currentPackage = this.modelConfig.package ?? state.package;
      const currentCarModel = this.modelConfig.carModel ?? state.carModel;
      const options = await fetchData(
        PACKAGES_ENDPOINT,
        `
        code=${currentPackage?.packageCode?.toUpperCase?.()}
        &model=${currentCarModel}
        &prstring=${state.prString}
        &price=${state.package?.price}
        &currency=${state.package?.currency}
        &locale=${state.locale}
        &version=${Env.VERSION}
        `.replace(/[\t\n\r ]+/g, ''),
      );

      if (!options || !options[property] || !Array.isArray(options[property])) {
        throw new Error(`Package ${currentPackage?.packageCode} has no ${property} property`);
      }

      this.options = options[property] as PackageType[];
    }

    return this.assignOptionsToEachPart(groupingKey);
  }

  assignOptionsToEachPart(groupingKey = this.groupingField) {
    //filter out options which are not for the selected seat type
    const uniqueIds = this.options
      .map(option => option[groupingKey])
      .filter((id, index, self) => self.indexOf(id) === index)
      .filter(Boolean);

    uniqueIds.forEach(id => {
      const model = new PackageModel<PackageType>({
        ...this.modelConfig,
      });

      // create a map of items so we can detect duplicates
      // duplicates can happen if we have two parts IDs for the same entry
      // then we create a group of options
      // e.g. if we have one option for both front seats and back seats
      const options = this.options.filter(option => option[groupingKey] === id);
      const usableOptions = model.addOptions(uniqueOptionsByProperty(options));

      if (!usableOptions.length) {
        // this happens when all menu options are inactive (from the API)
        // in this case, we shouldn't show an empty menu
        return;
      }

      this.allModels[model.id] = model;
      this.meta[model.id] = model.options[0];

      // ensure that prString that changes in the global store will reflect in all models
      store.onChange('prString', () => {
        model.prString = state.prString;
      });
    });

    this.filter();

    return this.models;
  }

  /**
   * All models have an ID which corresponds to the part ID
   * We want to group LF_, LR_ and others together in a separate property
   */
  groupPartsByIdPrefix(groupingLabels) {
    // reset groups
    this.groups = new Map();

    // we sort the ids to have front seat parts the first ones
    Object.entries(this.models)
      .sort(([id]) => (id.split('_')[0] === 'LF' ? -1 : 1))
      .forEach(([id, model]) => {
        const prefix = id.split('_')[0];
        // user defined label or default. Fallback to the prefix itself
        const key = groupingLabels ? groupingLabels[prefix] ?? groupingLabels.other ?? { label: prefix } : { label: 'all' };

        // we can't use Map.has() because the key is an object so we need to loop through the keys to find if labels match
        let existingGroup = Array.from(this.groups.keys()).find(group => group.label === key.label);

        if (!existingGroup) {
          // create new group key if one doesn't exist
          this.groups.set(key, []);
          existingGroup = key;
        }

        // Push to the group
        this.groups.get(existingGroup).push(model);
      });
  }

  validateSelections() {
    // We get the ids which are part of the PR string to check if we already have a selection there
    const prStringIds = this.getIdsFromPRString();
    Object.entries(this.selection).forEach(([id, value]) => {
      // We first check if there is a value in the field and secondly we check for a previous selection in the PR string.
      if (!value && !id.split(',').every(idPart => prStringIds.indexOf(idPart) !== -1)) {
        [...this.groups.keys()].forEach(key => {
          this.groups.get(key).forEach(group => {
            if (group.id === id) {
              group.error = 'This field is required';
            }
          });
        });
      }
    });
  }

  /** This method gets the Audi Exclusive Ids which are part of the PR string. Their particularity is they include _ and have a longer length */
  getIdsFromPRString = () => {
    return (
      state.prString
        .split(',')
        // We filter out the standard parts of the PR string
        .filter(string => string.split('_').length > 2)
        // We remove the selected color from the string and return only the ids
        .map(string => {
          const [id1, id2] = string.split('_');
          return `${id1}_${id2}`;
        })
    );
  };

  /**
   * Filter known models so that only those that match the filter in the first of model's options are returned
   */
  filter(value?: Partial<PackageModel>) {
    const filter = value ?? this.mutations.filter ?? null;

    if (!filter) {
      return this.resetFilter();
    }

    const filteredModels = {};

    Object.entries(filter).forEach(([prop, values]) => {
      if (!values) return;
      Object.entries(this.allModels).forEach(([id, model]) => {
        if (values.includes(model.options?.[0]?.[prop])) {
          filteredModels[id] = model;
        }
      });
    });

    this.models = filteredModels;
    return this.models;
  }

  resetFilter() {
    this.models = this.allModels;
    this.mutations.filter = null;
    return this.models;
  }

  /**
   * Calculate selection for each model
   */
  get selection() {
    return Object.values(this.models).reduce(
      (aggregate, model) => ({
        ...aggregate,
        [model.id]: model.getSelectedValue(),
      }),
      {},
    );
  }
}
