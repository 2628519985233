import { Env } from '@stencil/core';
import { AudiExclusivePackage } from '../config/package';
import { UnknownPackage, state } from '../store/configurator';
import { fetchData } from './fetch';
import { uniqueOptionsByProperty } from './unique';

export const PACKAGES_ENDPOINT = 'v2/packages';

function prStringToArray(prString: string): string[] {
  return prString.split(',');
}

function prStringStringify(prString: string[]): string {
  return prString.join(',');
}

export class PackageModel<PackageType = UnknownPackage> {
  package: AudiExclusivePackage;

  carModel: string;

  /**
   * Whatever options are returned from the API in addition to `prValue` string, which is the pre-computed PRstring value.
   */
  options: Array<PackageType & UnknownPackage> = [];

  /**
   * The property name of the package ID that will be prepended to selection value when generating PR string.
   */
  packageIdProp = 'id';

  /**
   * The constant value of the package ID that will be prepended to selection value when generating PR string.
   * Used with some menus like Q0Q0.
   * This takes precedence over packageIdProp.
   */
  packageIdConst: string;

  /**
   * Property that contains value to be appended to selection value when generating PR string.
   */
  packageValueProp = 'ae_color';

  /**
   * Error message to show when field is invalid
   */
  error: string = undefined;

  /**
   * Get ID of the package that's used to populate the PR string.
   */
  get id() {
    return this.packageIdConst ?? this.options[0]?.[this.packageIdProp];
  }

  prStringArray: string[];

  set prString(value: string[] | string) {
    this.prStringArray = Array.isArray(value) ? value : prStringToArray(value);
  }

  get prString(): string {
    return prStringStringify(this.prStringArray);
  }

  // Allow partial initialization
  constructor(config: Partial<Pick<PackageModel, 'prString' | 'packageIdConst' | 'package' | 'carModel'>> = {}) {
    const init = {
      package: state.package,
      prString: state.prString,
      carModel: state.carModel,
      ...config,
    };

    // Apply keys that are available in config
    Object.keys(init).forEach(key => {
      this[key] = init[key];
    });

    /** These packages need a different prefix than the other ones */
    const specialPrefixPackages = ['Q0Q0', 'O0O0'];
    if (specialPrefixPackages.indexOf(init.package?.packageCode?.toUpperCase()) !== -1 && !init.packageIdConst) {
      this.packageIdConst = 'LC_PR';
    }
  }

  /**
   * Fetch the package data for a given code from the API.
   */
  async fetchPackage(): Promise<PackageType[]> {
    const options = await fetchData(
      PACKAGES_ENDPOINT,
      `
      code=${this.package?.packageCode?.toUpperCase?.()}
      &model=${this.carModel.toUpperCase?.()}
      &prstring=${this.prString}
      &price=${this.package?.price}
      &currency=${this.package?.currency}
      &locale=${state.locale}
      &version=${Env.VERSION}
      `.replace(/[\t\n\r ]+/g, ''),
    );

    if (options?.values) {
      this.addOptions(options.values as PackageType[]);
    }

    return this.options;
  }

  /**
   * Manually add options to the model. Useful when they were pre-fetched.
   */
  addOptions(options: PackageType[]) {
    this.options = options;
    this.normalizeOptions();
    return this.options;
  }

  /**
   * Sometimes we need to get the entire option object, but we only have the ID.
   * This function allows you to get the option by any property that belongs to the option.
   * By default, the property is `id`.
   * @param value Value we are searching for
   * @param prop Property that contains the value
   * @returns Option object
   */
  getOption(value: unknown, prop = this.packageIdConst): PackageType {
    return this.options.find(option => option[prop] === value);
  }

  /**
   * Pre-generate option values for faster operations
   */
  normalizeOptions() {
    const options = this.options
      .map(option => ({
        ...option,
        prValue: this.getPrValue(option),
      }))
      // filter out inactive options
      .filter(option => option.is_active !== false && option.is_active !== 'false');
    // no dupes, yo!
    this.options = uniqueOptionsByProperty(options);
  }

  /**
   * Can connect one or multiple IDs to a value
   * For example `FW_SE` or `FW_SE,FW_AC`
   * These would become `FW_SE_AEIC001` and `FW_SE_AEIC001,FW_AC_AEIC001`
   */
  getPrValue(option) {
    const packageId = this.packageIdConst ?? option[this.packageIdProp];
    // in some instances there are multiple IDs for a single package
    const packageIds = packageId.split(',');
    const packageValue = option[this.packageValueProp];

    const values = packageIds.map(id => `${id}_${packageValue}`);
    const flattened = values.join(',');
    return flattened;
  }

  /**
   * Parses the PR string and tries to identify which options in the model are selected.
   */
  getSelectedValue(): PackageType {
    return (
      this.options.find(({ prValue }) => this.prStringArray.includes(prValue)) ??
      // groups of packages that contain the same material (such as "All leather seat parts") would be matched as a group here
      this.options.find(option =>
        option[this.packageIdProp]?.split(',').every(id => {
          return this.prStringArray.includes(`${id}_${option[this.packageValueProp]}`);
        }),
      )
    );
  }

  /**
   * Process selection of a new option.
   * Erase all previous selections and add the new one.
   * @returns string prString
   */
  select(option: PackageType): string {
    this.deletePackageFromPrString();
    this.addOptionToPrString(option);
    return this.prString;
  }

  /**
   * Delete all possible packages of this type (menu code) from the PR string.
   */
  deletePackageFromPrString() {
    let newPrStringArray;
    // if package id constant is declared then we'll remove any PR values that start with that constant
    if (this.packageIdConst) {
      newPrStringArray = this.prStringArray.filter(prValue => !prValue.startsWith(`${this.packageIdConst}_`));
    } else {
      const uniqueIds = [
        // set ensures uniqueness
        ...new Set(
          this.options
            // get IDs, split comma separated
            .map(({ id }) => id.split(','))
            // flatten the array so we don't have any nesting
            .flat(),
        ),
      ];
      newPrStringArray = this.prStringArray.filter(prValue => !uniqueIds.find(id => prValue.startsWith(`${id}_`)));
    }

    this.prString = newPrStringArray;
    return this.prString;
  }

  /**
   * Add selected option to the PR string
   */
  addOptionToPrString(option: PackageType) {
    const newPrStringArray = [...this.prStringArray];
    newPrStringArray.push(this.getPrValue(option));
    this.prString = newPrStringArray;
    return this.prString;
  }
}
