import { Env } from '@stencil/core';

export type PackageData = Record<string, unknown>;

export type ServerData = {
  values?: unknown[];
  [key: string]: unknown;
};

export type APIResponse = {
  // menu code when requesting a package
  code?: string;
  data: ServerData;
};

/**
 * Fetch data async for given params.
 */
export async function fetchData<T = ServerData>(endpoint: string, params: string): Promise<T> {
  const cached = cache(
    params
      .split('&')
      .map(prop => prop.split('=')[1])
      .join(':'),
  );

  if (cached) {
    return cached as T;
  }
  try {
    const res = await fetch(`${Env.AE_API_URL}/${endpoint}?${params}`);
    const json = (await res.json()) as APIResponse;
    const value = json.data;

    if (value) {
      return cache(
        params
          .split('&')
          .map(prop => prop.split('=')[1])
          .join(':'),
        value,
      ) as T;
    }

    return (value ?? {}) as T;
  } catch (e) {
    console.error('Failed fetching data', e);
    return {} as T;
  }
}

/**
 * In-memory cache storage
 */
const CACHE = {};

/**
 * Cache the package data for a given code.
 * If value isn't specified then return the cached value.
 */
function cache(code: string, value?: unknown): unknown {
  if (value) {
    CACHE[code] = value;
  }

  return CACHE[code];
}
